import { ChevronRightIcon } from "@heroicons/react/24/solid";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

import { MobileMenu } from "./MobileMenu";
import { NavigationHeader } from "./NavigationHeader";
import { Close } from "../../icons/close";

interface Navigation {
  darkMode: boolean;
  setDarkMode: (b: boolean) => void;
}

const HIDDEN_HEADER_PATHS = ["/asset/[contract]/[tokenId]/render"];
const TRANSPARENT_HEADER_PATHS = ["/", "/onchain"];
const TRANSPARENT_SCROLL_Y_LIMIT = 60;
const CONSTRAIN_WIDTH_HEADER_PATHS = ["/"];

export const Navigation = ({ darkMode, setDarkMode }: Navigation) => {
  const router = useRouter();

  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const isTransparentHeaderPath = TRANSPARENT_HEADER_PATHS.includes(
    router.pathname
  );
  const isConstrainWidthPath = CONSTRAIN_WIDTH_HEADER_PATHS.includes(
    router.pathname
  );
  const isHideHeaderPath = HIDDEN_HEADER_PATHS.includes(router.pathname);

  // close mobile menu
  useEffect(() => {
    if (showMobileMenu) setShowMobileMenu(false);
  }, [router.pathname]);

  // dertermine if page is scolled to enable/disable header transparency
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition >= TRANSPARENT_SCROLL_Y_LIMIT);
    };

    if (isTransparentHeaderPath) {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [isTransparentHeaderPath]);

  // Header transparency goes away as you scroll
  const isTransparent = useMemo(
    () => isTransparentHeaderPath && !isScrolled,
    [isTransparentHeaderPath, isScrolled]
  );

  if (isHideHeaderPath) return null;

  return (
    <div className="top-0 z-40 sticky">
      <OnChainBanner />
      <NavigationHeader
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        showMobileMenu={() => setShowMobileMenu(true)}
        constrainMaxWidth={isConstrainWidthPath}
        transparent={isTransparent}
        border={!isTransparentHeaderPath} // turn of border if we use transparency
      />
      <MobileMenu
        show={showMobileMenu}
        closeMenu={() => setShowMobileMenu(false)}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />
    </div>
  );
};

const OnChainBanner = () => {
  const [hideBanner, setHideBanner] = useState(false);
  if (hideBanner) return null;

  return (
    <div className="relative">
      <Link href="/onchain" className="block">
        <div className="flex flex-col sm:flex-row justify-between items-center bg-black py-2 px-6 sm:px-10 text-white gap-2 text-sm">
          <div className="font-medium text-center sm:text-left">
            🎉 Art Blocks at 4 years: Now with full on-chain support!
          </div>
          <div className="hidden sm:flex items-center gap-1.5 text-neutral-300 hover:text-white transition-colors">
            Learn more
            <ChevronRightIcon className="h-3.5 w-3.5" />
          </div>
        </div>
      </Link>
      <button
        onClick={() => setHideBanner(true)}
        className="ml-5 absolute right-2 top-1/2 -translate-y-1/2 p-1 text-neutral-300 hover:text-white transition-colors"
      >
        <Close className="h-4 w-4" />
      </button>
    </div>
  );
};
